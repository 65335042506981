import styled from "styled-components";

export const StyledHeader = styled.header`
  text-align: center;
  border: 10px solid saddlebrown;
  border-radius: 15px 15px 0 0;
  background-color: white;
  background-image: url(/img/headerbg2.jpg), url(/img/dirk.jpg);
  background-position: center left, center right;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  height: 6rem;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0px 10px 15px black;
  // iphone6 horizontal
  @media (min-width: 667px) {
    flex-basis: 47%;
  }
  @media (min-width: 768px) {
    flex-basis: 31.1%;
  }
  @media (min-width: 768px) {
    flex-basis: 22.8%;
  }
  @media (min-width: 1600px) {
    flex-basis: 18%;
  }
  @media print {
    display: none;
  }
  h1 {
    margin: 0 auto;
    padding: 1%;
    background: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 190, 0, 1) 25%,
      rgba(255, 187, 0, 1) 75%,
      rgba(255, 255, 255, 1) 100%
    );
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    text-shadow: 1px 1px 5px rgba(255, 190, 0, 1);
    letter-spacing: 1px;
    font-size: 2.5rem;
    font-family: "Yellowtail";
    min-height: -webkit-fill-available;
    @media (min-width: 768px) {
      font-size: 3.5rem;
      width: calc(100% - 300px);
    }
  }
  h1 a {
    color: white;
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50%;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:hover {
      color: orangered;
      text-shadow: 1px 1px 5px white;
    }
  }
  img {
    position: absolute;
    top: -4.5rem;
    left: 50%;
    width: 15rem;
    height: 15rem;
    margin-left: -7.5rem;
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
    z-index: 1;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  background: orangered;
  border-top: 1px inset white;
  border-bottom: 1px inset white;
  padding: 0.25% 10px;
  box-shadow: 0px 10px 15px black;
  @media (min-width: 768px) {
    align-items: center;
  }
  @media print {
    display: none;
  }
`;
export const StyledNavButtons = styled.div`
  flex: 1;
  button {
    margin-right: 0.5rem;
  }
`;
export const StyledNavList = styled.ul`
  margin: 0;
  padding: 0;
  flex: 8;
  @media (min-width: 768px) {
    text-align: center;
  }
  &:last-child {
    flex: 1;
    text-align: right;
  }
`;
export const StyledNavItem = styled.li`
  display: inline-flex;
  padding: 0 1rem;
  @media (max-width: 767px) {
    display: block;
    padding: 1%;
    margin: 2% 0;
  }
  a {
    color: white;
    font-weight: 700;
    font-family: monospace;
    font-size: 1.5rem;
    text-decoration: none;
    text-shadow: 0 0 3px black;
    margin: 2px 0;
    padding: 2px 0.5rem;
    border-radius: 4px;
    border: 1px inset orangered;
    white-space: nowrap;
    &:hover,
    &:active {
      // text-shadow: 0 0 2px white;
      border-color: #fff;
    }
    &.active {
      color: #fff;
      border-color: #fff;
      background: saddlebrown;
    }
  }
  &.right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const StyledNavMessage = styled.div`
  padding: 1rem calc(1rem + 10px);
  background: darkseagreen;
  border-top: 1px solid white;
  border-bottom: 2px solid white;
  animation: 1s ease-out 0s 1 popInMessage;
  box-shadow: 0px 10px 15px black;
`;

export const StyledSelect = styled.select`
  flex: 1;
  background: orangered;
  color: white;
  border: none;
  font-weight: 700;
  font-family: monospace;
  font-size: 1.3rem;
  text-shadow: 0 0 3px black;
  outline: none;
  option {
    margin: 0;
    padding: 0;
  }
  @media (min-width: 667px) {
    display: none;
  }
`;

export const StyledContainer = styled.div`
  font-family: Raleway, sans-serif;
  background: darkgrey;
  border-radius: 15px 15px 0 0;
  max-width: 1600px;
  margin: 0 auto;
`;

export const StyledContent = styled.div`
  background: #dedede;
  border: 10px solid saddlebrown;
  box-shadow: 0px 10px 15px black;
  text-shadow: 1px 1px 1px #ddd;
  padding: 1rem;
  min-height: 500px;
  @media print {
    border: none;
    box-shadow: none;
    padding: 0;
  }
`;

export const StyledFooter = styled.footer`
  text-align: center;
  border: 10px solid saddlebrown;
  background-color: black;
  color: grey;
  box-shadow: 0px 10px 15px black;
  font-family: Raleway, sans-serif;
  a {
    color: grey;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
  .language {
    float: right;
    font-size: 2rem;
    span {
      margin-right: 1rem;
      opacity: 0.5;
      cursor: pointer;
      text-shadow: 0 0 10px white;
      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }
  @media print {
    display: none;
  }
`;
