export default {
  ACCOUNT: "Account",
  ADMINISTRATION: "Admin",
  ALL_RECORDS: "My Records",
  BUY_ON_DISCOGS: "Buy through Discogs",
  CATEGORY: "Category",
  COVER_TYPE: "Cover type",
  CURRENTLY_IN_JUKEBOX: "Currently in my Jukebox",
  EDIT_RECORD: "Edit record",
  FOR_SALE: "For Sale",
  JUKEBOX_ON_SHUFFLE_TEXT:
    "Jukebox will shuffle records for you. If you like, you can also pick your favorite song from the list.",
  LAST_UPDATED: "Last updated",
  LOGIN: "Login",
  N_OF_N: " of ",
  NEXT: "Next",
  NO_RESULTS: "No results found.",
  PAGE: "Page",
  PLAY: "Play",
  POSITION: "Position",
  PREVIOUS: "Previous",
  RECORDS: "records",
  RECORD_IS_IN_MY_JUKEBOX: "Record is in my Jukebox",
  SEARCH_FOR_ARTIST_OR_TITLE: "🔎 Search for Artist or Title...",
  SORT_ASCENDING: "Sort Ascending",
  SORT_BY_ARTIST: "Sort by Artist",
  SORT_BY_UPDATED: "Sort by Last Updated",
  SORT_DESCENDING: "Sort Descending",
  THERE_IS_NO_SONG_SET_YET_FOR_:
    "There currently is no Record assigned to ",
  THESE_RECORDS_HAVE_NO_POSITION:
    "These records have no Position set yet:",
};
