import React, { Fragment } from "react";
import { Link } from "gatsby";

import { AuthUserContext } from "../Account/Session";
import {
  StyledHeader,
  StyledNav,
  StyledNavButtons,
  StyledNavList,
  StyledNavItem,
  StyledNavMessage,
} from "./styles";
import { ROUTES, ROLES } from "../../constants";
import { CATEGORIES } from "../../constants";
import { getQueryParams, getString } from "../../helpers";

const Navigation = ({ location }) => {
  const { pathname, hash, state } = location;
  let message = "";
  if (state && state.message) {
    message = state.message;
  }
  let activeRoute = pathname;
  if (pathname === ROUTES.RECORDS) {
    const { categorie } = getQueryParams(location);
    switch (categorie) {
      case CATEGORIES.FOR_SALE.ID:
        activeRoute = ROUTES.RECORDS_FOR_SALE;
        break;
      case CATEGORIES.IN_JUKEBOX.ID:
        activeRoute = ROUTES.RECORDS_IN_JUKEBOX;
        break;
      default:
        activeRoute = ROUTES.RECORDS;
    }
  } else if (hash === "#edit") {
    activeRoute = ROUTES.ADMIN;
  }

  return (
    <Fragment>
      <StyledHeader>
        <h1>
          <Link to="/">Dirk's Jukebox</Link>
          <img src="/img/record.png" alt="You spin me right round" />
        </h1>
      </StyledHeader>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <NavigationAuth
              authUser={authUser}
              activeRoute={activeRoute}
            />
          ) : (
            <NavigationNonAuth activeRoute={activeRoute} />
          )
        }
      </AuthUserContext.Consumer>
      {message && <StyledNavMessage>{message}</StyledNavMessage>}
    </Fragment>
  );
};

const NavigationAuth = ({ authUser, activeRoute }) => (
  <StyledNav>
    <StyledNavButtons>
      {navigator.standalone && (
        <Fragment>
          <button
            onClick={(e) => {
              e.preventDefault();
              history.back();
            }}
            title="Terug"
            disabled={!history.back}
          >
            ←
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              history.forward();
            }}
            title="Vooruit"
            disabled={!history.forward}
          >
            →
          </button>
        </Fragment>
      )}
    </StyledNavButtons>
    <StyledNavList>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS}
          className={
            activeRoute === ROUTES.RECORDS ? "active" : undefined
          }
        >
          {getString("ALL_RECORDS")}
        </Link>
      </StyledNavItem>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS_FOR_SALE}
          className={
            activeRoute === ROUTES.RECORDS_FOR_SALE
              ? "active"
              : undefined
          }
        >
          {getString("FOR_SALE")}
        </Link>
      </StyledNavItem>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS_IN_JUKEBOX}
          className={
            activeRoute === ROUTES.RECORDS_IN_JUKEBOX
              ? "active"
              : undefined
          }
        >
          {getString("CURRENTLY_IN_JUKEBOX")}
        </Link>
      </StyledNavItem>

      {!!authUser.roles[ROLES.ADMIN] && (
        <StyledNavItem>
          <Link
            to={ROUTES.ADMIN}
            className={
              activeRoute === ROUTES.ADMIN ? "active" : undefined
            }
          >
            {getString("ADMINISTRATION")}
          </Link>
        </StyledNavItem>
      )}
    </StyledNavList>

    <StyledNavList>
      <StyledNavItem>
        <Link
          to={ROUTES.ACCOUNT}
          className={
            activeRoute === ROUTES.ACCOUNT ? "active" : undefined
          }
        >
          {getString("ACCOUNT")}
        </Link>
      </StyledNavItem>
    </StyledNavList>
  </StyledNav>
);

const NavigationNonAuth = ({ activeRoute }) => (
  <StyledNav>
    <StyledNavList>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS}
          className={
            activeRoute === ROUTES.RECORDS ? "active" : undefined
          }
        >
          {getString("ALL_RECORDS")}
        </Link>
      </StyledNavItem>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS_FOR_SALE}
          className={
            activeRoute === ROUTES.RECORDS_FOR_SALE
              ? "active"
              : undefined
          }
        >
          {getString("FOR_SALE")}
        </Link>
      </StyledNavItem>
      <StyledNavItem>
        <Link
          to={ROUTES.RECORDS_IN_JUKEBOX}
          className={
            activeRoute === ROUTES.RECORDS_IN_JUKEBOX
              ? "active"
              : undefined
          }
        >
          {getString("CURRENTLY_IN_JUKEBOX")}
        </Link>
      </StyledNavItem>
    </StyledNavList>
    <StyledNavList>
      <StyledNavItem>
        <Link
          to={ROUTES.SIGN_IN}
          className={
            activeRoute === ROUTES.SIGN_IN ? "active" : undefined
          }
        >
          {getString("LOGIN")}
        </Link>
      </StyledNavItem>
    </StyledNavList>
  </StyledNav>
);

export default Navigation;
