export default {
  ACCOUNT: "Account",
  ADMINISTRATION: "Beheer",
  ALL_RECORDS: "Mijn Singles",
  BUY_ON_DISCOGS: "Kopen via Discogs",
  CATEGORY: "Categorie",
  COVER_TYPE: "Type hoes",
  CURRENTLY_IN_JUKEBOX: "Nu in m'n Jukebox",
  EDIT_RECORD: "Single wijzigen",
  FOR_SALE: "Te Koop",
  JUKEBOX_ON_SHUFFLE_TEXT:
    "Jukebox kiest zelf een willekeurige volgorde, maar je kunt natuurlijk ook zelf nummers uit de lijst kiezen!",
  LAST_UPDATED: "Laatst gewijzigd",
  LOGIN: "Inloggen",
  N_OF_N: " van ",
  NEXT: "Volgende",
  NO_RESULTS: "Niets gevonden.",
  PAGE: "Pagina",
  PLAY: "Afspelen",
  PLAY_A_SIDE: "A Kant afspelen (YouTube)",
  PLAY_B_SIDE: "B Kant afspelen (YouTube)",
  POSITION: "Positie",
  PREVIOUS: "Vorige",
  RECORDS: "singles",
  RECORD_IS_IN_MY_JUKEBOX: "Single zit nu in m'n jukebox",
  SEARCH_FOR_ARTIST_OR_TITLE: "🔎 Zoek naar artiest of titel...",
  SORT_ASCENDING: "Oplopend sorteren",
  SORT_BY_ARTIST: "Sorteer op Artiest",
  SORT_BY_UPDATED: "Sorteer op Laatst gewijzigd",
  SORT_DESCENDING: "Aflopend sorteren",
  THERE_IS_NO_SONG_SET_YET_FOR_: "Er zit momenteel geen single in ",
  THESE_RECORDS_HAVE_NO_POSITION:
    "Deze singles hebben nog geen positie instelling:",
};
