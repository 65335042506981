export const PAGE_SIZE = 20;
export const DEFAULT_SORT_BY = "artist";
export const DEFAULT_SORT_ORDER = "ASC";

export const ROUTES = {
  LANDING: "/",
  SIGN_UP: "/signup",
  SIGN_IN: "/signin",
  ACCOUNT: "/account",
  PASSWORD_FORGET: "/pw-forget",
  ADMIN: "/admin",
  RECORDS: "/records/", // generated by gatsby-node
  RECORDS_FOR_SALE: "/records/#for_sale",
  RECORDS_IN_JUKEBOX: "/records/#in_jukebox",
  RECORD: "/record/", // generated by gatsby-node
  RECORD_BY_ID: "/record_by_id", // used for newly added records without a slug
  ADD_RECORD: "/add-record",
};

export const ROLES = {
  ADMIN: "ADMIN",
};

export const CATEGORIES = {
  FOR_SALE: {
    ID: 3,
    LABEL_NL: "Te Koop",
    LABEL_EN: "For Sale",
    ROUTE: ROUTES.RECORDS_FOR_SALE,
  },
  NOT_IN_JUKEBOX: {
    ID: 4,
    LABEL_NL: "Niet in de Jukebox",
    LABEL_EN: "Not currently in Jukebox",
    ROUTE: null,
  },
  DELETED: {
    ID: 5,
    LABEL_NL: "Verwijderd",
    LABEL_EN: "Deleted",
    ROUTE: null,
  },
  IN_JUKEBOX: {
    ID: 10,
    LABEL_NL: "Nu in mijn Jukebox",
    LABEL_EN: "Currentky in my Jukebox",
    ROUTE: ROUTES.RECORDS_IN_JUKEBOX,
  },
};

export const COVERS = {
  UNKNOWN: {
    ID: 1,
    LABEL_NL: "Onbekend",
    LABEL_EN: "Unknown",
  },
  PHOTO: {
    ID: 5,
    LABEL_NL: "Fotohoes",
    LABEL_EN: "Photo cover",
  },
  PHOTO_UNSCANNED: {
    ID: 3,
    LABEL_NL: "Fotohoes (nog niet gescand)",
    LABEL_EN: "Photo cover (not scanned yet)",
  },
  WHITE: {
    ID: 2,
    LABEL_NL: "Witte hoes",
    LABEL_EN: "White cover",
  },
  FACTORY: {
    ID: 4,
    LABEL_NL: "Fabriekshoes",
    LABEL_EN: "Factory cover",
  },
};

export const OPERATIONS = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};

export const EMPTY_RECORD = {
  artist: "",
  a_side: "",
  b_side: "",
  cover_id: 1,
  cat_id: 4,
  imgUrl: "",
};

export const JUKEBOX_SECTION_LENGTH = 8;
export const JUKEBOX_SECTIONS = [
  ["A", "B"],
  ["C", "D"],
  ["E", "F"],
  ["G", "H"],
  ["J", "K"],
  ["L", "M"],
  ["N", "P"],
  ["Q", "R"],
  ["S", "T"],
  ["U", "V"],
];
