import React, { Fragment, useState, useEffect } from "react";
import Helmet from "react-helmet";

import Navigation from "./Navigation";
import getFirebase, { FirebaseContext } from "./Firebase";
import withAuthentication from "./Account/Session/withAuthentication";
import "../../static/global.css";
import {
  StyledContainer,
  StyledContent,
  StyledFooter,
} from "./Navigation/styles";

const Layout = (props) => {
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    const app = import("firebase/app");
    const auth = import("firebase/auth");
    const database = import("firebase/database");

    Promise.all([app, auth, database]).then((values) => {
      const fireb = getFirebase(values[0]);

      setFirebase(fireb);
    });
  });

  return (
    <FirebaseContext.Provider value={firebase}>
      <AppWithAuthentication {...props} />
    </FirebaseContext.Provider>
  );
};

const AppWithAuthentication = withAuthentication(
  ({ children, location }) => {
    const [lastUpdated, setLastUpdated] = useState(null);

    useEffect(() => {
      if (typeof localStorage !== undefined) {
        const lastUpdated = localStorage.getItem("lastUpdated") || 0;
        setLastUpdated(parseInt(lastUpdated, 10));
      }
    }, []);

    const setLanguage = (language) => {
      if (process.browser) {
        localStorage.setItem("language", language);
        window.location.reload();
      }
    };

    const getLanguage = () => {
      if (process.browser) {
        return localStorage.getItem("language") || "EN";
      }
      return "EN";
    };

    return (
      <Fragment>
        <Helmet>
          <title>Dirk's Jukebox</title>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Raleway"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap"
          />
        </Helmet>
        <StyledContainer>
          <Navigation location={location} />
          <StyledContent>{children}</StyledContent>
          <StyledFooter>
            <div>
              {lastUpdated > 0 && (
                <p>
                  <small>
                    Laatste database update:{" "}
                    {new Date(lastUpdated).toLocaleString()}
                  </small>
                </p>
              )}

              <p>
                &copy;{new Date().getFullYear()}:{" "}
                <a href="https://dirkkater.nl">Dirk Kater</a> /
                Development:{" "}
                <a
                  href="https://markkater.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mark Kater
                </a>
              </p>
            </div>
            <div className="language">
              <span
                className={getLanguage() === "NL" ? "active" : null}
                title="Taal: Nederlands"
                onClick={() => setLanguage("NL")}
              >
                🇳🇱
              </span>
              <span
                className={getLanguage() === "EN" ? "active" : null}
                title="Site language: English"
                onClick={() => setLanguage("EN")}
              >
                🇺🇸
              </span>
            </div>
          </StyledFooter>
        </StyledContainer>
      </Fragment>
    );
  },
);

export default Layout;
