import {
  ROUTES,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_ORDER,
  OPERATIONS,
  CATEGORIES,
} from "./constants";
import * as LOCALES from "./locales";

export const getCategoryName = (cat_id) => {
  switch (parseInt(cat_id, 10)) {
    case 3:
      return "Te koop";
    case 4:
      return "Niet in de jukebox";
    case 5:
      return "Verwijderd";
    case 10:
      return "In de Jukebox!";
    default:
      return "Onbekend";
  }
};

export const getCoverType = (cover_id) => {
  switch (parseInt(cover_id, 10)) {
    case 2:
      return "Witte hoes";
    case 3:
      return "Fotohoes (nog niet gescand)";
    case 4:
      return "Fabriekshoes";
    case 5:
      return "Fotohoes";
    case 1:
    default:
      return "Onbekend";
  }
};

export const getQueryParams = (location) => {
  const { search, hash } = location;
  const searchTerm = search
    ? decodeURI(search.substring(1)).toLowerCase()
    : "";

  const hashChunks = hash.substring(1).split("|");
  let sortBy = DEFAULT_SORT_BY;
  let sortOrder = DEFAULT_SORT_ORDER;
  let coverId = "";

  hashChunks.forEach((chunk) => {
    if (chunk.indexOf("s=") !== -1) {
      sortBy = chunk.substring(2);
    }
    if (chunk.indexOf("o=") !== -1) {
      sortOrder = chunk.substring(2);
    }
    if (chunk.indexOf("c=") !== -1) {
      coverId = chunk.substring(2);
    }
  });

  let categorie = null;
  if (hashChunks.includes("for_sale")) {
    categorie = 3;
  } else if (hashChunks.includes("in_jukebox")) {
    categorie = 10;
  }

  return {
    searchTerm,
    sortBy,
    sortOrder,
    categorie,
    coverId,
  };
};

export const getUpdatedUrl = (pageParams) => {
  const {
    pageNum,
    searchTerm,
    sortBy,
    sortOrder,
    categorie,
    coverId,
  } = pageParams;

  const hashParams = [];
  let url = `${ROUTES.RECORDS}`;
  if (pageNum > 1) {
    url += `${pageNum}/`;
  }
  if (searchTerm) {
    url += `?${searchTerm}`;
  }
  if (categorie === 3) {
    hashParams.push("for_sale");
  }
  if (categorie === 10) {
    hashParams.push("in_jukebox");
  }
  hashParams.push(`s=${sortBy}`);
  hashParams.push(`o=${sortOrder}`);
  hashParams.push(`c=${coverId}`);
  return (url += `#${hashParams.join("|")}`);
};

export const fetchRecordFromLocalStorage = (record) => {
  if (process.browser) {
    const localRecords = localStorage.getItem("localRecords");
    if (localRecords) {
      return JSON.parse(localRecords)[record.id];
    }
  }
  return record;
};

export const updateRecordInLocalStorage = (record) => {
  const localRecords = JSON.parse(
    localStorage.getItem("localRecords"),
  );
  localRecords[record.id] = record;

  localStorage.setItem("localRecords", JSON.stringify(localRecords));
  localStorage.setItem("lastUpdated", record.lastUpdated);
  return true;
};

export const saveRecord = async (firebase, record, operation) => {
  if (operation === OPERATIONS.DELETE) {
    if (
      !window.confirm(
        "Weet je zeker dat je deze single wilt verwijderen?",
      )
    ) {
      return false;
    } else {
      record.cat_id = CATEGORIES.DELETED.ID;
    }
  }
  record.lastUpdated = new Date().getTime();
  const recordRef = firebase.record(record.id);
  await recordRef.set(record, (error) => {
    if (error) {
      alert("Er ging iets fout. Probeer opnieuw.");
    } else {
      updateRecordInLocalStorage(record, operation);
    }
  });
  firebase.meta("lastUpdated").set(record.lastUpdated);

  if (operation === OPERATIONS.CREATE) {
    firebase.meta("latestRecordId").set(record.id);
  }
  return true;
};

export const shuffle = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

export const getString = (key, params) => {
  let language = "EN";
  if (process.browser && localStorage.getItem("language")) {
    language = localStorage.getItem("language");
  } else if (process.browser && navigator?.language) {
    language = navigator.language.substring(0, 2);
  }
  const strings = LOCALES[language.toUpperCase()];
  return strings[key];
};
